import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiURI } from 'src/app/secure/ApiURI';
import { UserService } from 'src/app/userservice/user.service';

@Component({
  selector: 'app-getquote',
  templateUrl: './getquote.component.html',
  styleUrls: ['./getquote.component.scss']
})
export class GetquoteComponent implements OnInit {
  formGroup!: FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';

  firstImgPath!: any;
  quoteproductName!: any;

  constructor(private formBuilder: FormBuilder,private dataService: UserService,
    public apiURI: ApiURI) {
    this.firstImgPath = localStorage.getItem('quoteimage');
    this.quoteproductName = localStorage.getItem('quoteproduct');

     }

  ngOnInit(): void {
    this.createForm();
   // this.setChangeValidate()
  }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formGroup = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.pattern(emailregex)], this.checkInUseEmail],
      'name': [null, [Validators.required, Validators.minLength(3)]],
      // 'password': [null, [Validators.required, this.checkPassword]],
      'description': [null, [Validators.required, Validators.minLength(50), Validators.maxLength(200)]]
      //,
      // 'validate': ''
    });
  }

  // setChangeValidate() {
  //   this.formGroup.controls['validate'].valueChanges.subscribe(
  //     (validate) => {
  //       if (validate == '1') {
  //         this.formGroup.controls['name'].setValidators([Validators.required, Validators.minLength(3)]);
  //         this.titleAlert = "You need to specify at least 3 characters";
  //       } else {
  //         this.formGroup.controls['name'].setValidators(Validators.required);
  //       }
  //       this.formGroup.controls['name'].updateValueAndValidity();
  //     }
  //   )
  // }

  // get name() {
  //   return this.formGroup.get('name') as FormControl
  // }

  // checkPassword(control:any) {
  //   let enteredPassword = control.value
  //   let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  //   return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  // }

  checkInUseEmail(control:any) {
    debugger;
    // mimic http database access
    let db = ['tony@gmail.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 1000)
    })
  }



  getErrorEmail() {
    return this.formGroup.controls['email'].hasError('required') ? 'This field is required' :
      this.formGroup.controls['email'].hasError('pattern') ? 'Not a valid emailaddress' :
        this.formGroup.controls['email'].hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
  }

  // getErrorPassword() {
  //   return this.formGroup.controls['password'].hasError('required') ? 'This field is required (at least eight characters, one uppercase letter and one number)' :
  //     this.formGroup.controls['password'].hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  // }

  onSubmit(post:any) {
    this.post = post;
  }

  GetQuoteSubmit() {
    debugger;
    const params = {
      PageName:"quote",
      ProductName:this.quoteproductName,
      Name: this.formGroup.value.name,
      EmailAddress: this.formGroup.value.email,
      Description: this.formGroup.value.description
    };
    if (this.formGroup.valid) {
      this.dataService.PostData(this.apiURI.SendMail, params).subscribe(data => {
        this.post = data;
      })
    }
    else{
     //
    }
  }

}
