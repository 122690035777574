import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AccessToken'));
   
  constructor(private http: HttpClient) { }

  GetData(url: string, body: any) {
    return this.http.get(url, body);
  }
  PostData(url: string, body: any) {
    return this.http.post(url, body);
  }
  GetDataSecure(url: string) {
    return this.http.get(url, { headers: this.headers });
  }
  PostDataSecure(url: string, body: any) {
    return this.http.post(url, body, { headers: this.headers });
  }
}
