<div class="container-fluid">
  <footer>
    <div class="row  row-2 ">
      <div class="col-12">
        <div class="row row-2 text-content-center">
          <div class="col-md-2">
            <!-- <h5><span> <i class="fa fa-firefox text-light" aria-hidden="true"></i></span><b> Prathamesh
                                Enterprises</b></h5> -->
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <i class="fa fa-map-marker" aria-hidden="true"> : </i>
                <span>Prathamesh Enterprises, Birdawdi, Ambethan Road, Behind Jotiba Mandir, Chakan, Pune - 410501 </span>
              </div>
            </div>
            <div class="row center">
              <div class="col-md-3"></div>
              <div class="col-md-5">
                <i class="fa fa-envelope-o"> : </i>
                <span style="color: orange;"> prathameshenterprises.chakan@gmail.com</span>
              </div>
              <div class="col-md-4">
                <i class="fa fa-mobile"> : </i>
                <span> +91 9561370024, +91 7843030025 </span>
              </div>
            </div>

          </div>
          <div class="col-md-2">

          </div>
        </div>
      </div>
    </div>
    <div class="row row-1">
      <div class="col-md-4 ">
        <div class="cust">
          <small class="cust">&#9400; 2021 Prathamesh Enterprises</small>
        </div>
      </div>
      <div class="col-md-5 center ">
        <div class="template-demo">
          <button type="button" class="btn btn-social-icon btn-whatsapp btn-rounded">
            <a href="https://wa.me/+919561370024?text=Hello there!" id="popupwhatsapp" target="popup"
              onclick="window.open('https://wa.me/+919561370024?text=Hello there!','popup','width=500,height=500'); return false;">
              <i class='fa fa-whatsapp fa-2x'></i></a>
          </button>
          <button type="button" class="btn btn-social-icon btn-facebook btn-rounded"><i
              class="fa fa-facebook"></i></button>
          <button type="button" class="btn btn-social-icon btn-youtube btn-rounded"><i
              class="fa fa-youtube"></i></button>
          <button type="button" class="btn btn-social-icon btn-twitter btn-rounded"><i
              class="fa fa-twitter"></i></button>
          <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded"><i
              class="fa fa-linkedin"></i></button>
          <button type="button" class="btn btn-social-icon btn-instagram btn-rounded"><i
              class="fa fa-instagram"></i></button>
        </div>
      </div>
      <div class="col-md-3 ">
        <div class="cust">
          <small class="cust"> Designed By :
            <span><a class="cust" style="color: orange;" href="https://www.linkedin.com/in/hanumant-sawant-053a788a"
                target="_blank"> Hanumant Sawant</a>
            </span></small>
        </div>
      </div>


    </div>
  </footer>
</div>
