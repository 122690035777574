
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiURI } from 'src/app/secure/ApiURI';
import { UserService } from 'src/app/userservice/user.service';


@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.scss']
})
export class UserloginComponent implements OnInit {

  hide = true;
  accessToken: any;

  constructor(private dataService: UserService,
    public apiURI: ApiURI,
    private router: Router) {
  }

  loginForm = new FormGroup({
    username: new FormControl(null, [ Validators.required,
                                Validators.minLength(4),
                                Validators.maxLength(10)]),

    password: new FormControl(null,  [ Validators.required,
                                    Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
                                  ]),
});

  ngOnInit(): void {
  }

  LoginSubmit() {
    debugger;
    const params = {
      UserName: this.loginForm.value.username,
      Password: this.loginForm.value.password
    };
    if (this.loginForm.valid) {
      // this.dataService.PostData(this.apiURI.Login, params).subscribe(data => {
      //   this.accessToken = data;
      //   localStorage.setItem('AccessToken', this.accessToken);
      // },
      // err=>{
      //   //
      // })
      localStorage.setItem('user', "Hanumant");
      window.location.href="http://prathameshenterprises.com/home";
    }
    else{
     //
    }
  }

}
