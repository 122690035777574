import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewonindiamart',
  templateUrl: './viewonindiamart.component.html',
  styleUrls: ['./viewonindiamart.component.scss']
})
export class ViewonindiamartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  OnClickTrust() {
    window.open("https://trustseal.indiamart.com/members/prathameshenterprisespune", 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600');
  }

  OnClickMember() {
    window.open("https://www.indiamart.com/prathameshenterprisespune/", "_blank");
  }
  OnClickPay(){
    window.open("https://paywith.indiamart.com/invoice/#/mdc/29699695", "_blank");
  }
}
