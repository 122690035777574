<app-header></app-header>
<div class="pe-bg">
    <div class="container py-5">
        <div class="row text-center text-white mb-9">
            <div class="col-lg-7 mx-auto">
                <h1 class="display-9">Raw Material List</h1>
            </div>
        </div>

        <!-- Image model start -->

        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-10">
                <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1"
                    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="myModalLabel">{{imgtitle}}</h4>
                                <i class="fa fa-times" aria-hidden="true" (click)="hide()"></i>
                            </div>
                            <div class="modal-body center">
                                <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active" data-interval="10000">
                                            <img [src]='imagepath' class="img-fluid" alt="">
                                        </div>
                                        <div class="carousel-item" data-interval="2000"
                                            *ngFor="let imgsrc of ImagesList">
                                            <img [src]='imgsrc.Image' class="img-fluid" alt="">
                                        </div>

                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleInterval" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleInterval" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Image model end -->

        <div class="row">
            <div class="col-lg-12 mx-auto">
                <ul class="list-group shadow">
                    <li class="list-group-item" *ngFor='let product of rawmaterialList'>
                        <div class="row">
                            <div class="col-md-3">
                                <img [src]='product.Image' (click)="show(product.Image,product.Name,product.ModelName)"
                                    class="listimg img-thumbnail" matTooltip="Click to View Images"
                                    matTooltipPosition="below">
                            </div>
                            <div class="col-md-9">
                                <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                                    <div class="media-body order-2 order-lg-1">
                                        <h4 class="text-center">{{product.Name}}</h4>
                                        <p class="text-muted mb-0">
                                            {{product.Description}}
                                        </p>
                                        <div class="d-flex align-items-center justify-content-between mt-1">
                                            <h6 class="price purple">current price : <span
                                                    class="orange">{{product.Price}}</span></h6>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="SetCurrentModelName(product.ModelName)"
                                class="btn btn-primary btn-round center">More Details</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>