import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetails, Product, ProductService, Images, ImageDetails } from '../productData/product-details-data';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.scss']
})
export class ProductlistComponent implements OnInit {

  imagepath = null;
  imgtitle = null;
  showModal!: boolean;

  productList!: Product[];
  ImagesList!: Images[];

  constructor(private productServ: ProductService, private router: Router) {
    debugger;
    localStorage.removeItem('ModelName');
    this.productList = ProductDetails;
    this.ImagesList = ImageDetails;
  }

  ngOnInit(): void {
  }

  SetCurrentModelName(modelname: any) {
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.router.navigateByUrl('/productdetails');
  }

  show(img: any, title: any, modelname: any) {
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.imagepath = img;
    this.imgtitle = title;
    this.showModal = true;
    debugger;
    this.ImagesList = this.productServ.getImagesByModelName(localStorage.getItem('ModelName'));
    this.ImagesList =this.ImagesList.slice(1);
  }
  hide() {
    this.showModal = false;
  }
}
