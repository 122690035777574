<app-header></app-header>
<div class="main-div">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div fxLayoutAlign="center center" fxFlexFill class="divlogin">
                    <mat-card class="login-form">
                        <mat-toolbar class="bg-orange" fxLayoutAlign="center center">
                             Sign In
                        </mat-toolbar>
                        <form fxLayoutAlign="stretch" fxLayout="column" class="login-form" [formGroup]="loginForm">

                            <mat-form-field>

                                <mat-label>Enter Username</mat-label>
                                <input matInput type="text" id="_username" formControlName="username"
                                    placeholder="Username">
                                <mat-error>
                                    Username is <strong>required</strong>
                                </mat-error>

                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Enter Password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" id="_password"
                                    formControlName="password" placeholder="password">
                                <label mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" placeholder="Password..."
                                    [attr.aria-pressed]="hide" >
                                    <mat-icon  [matTooltip]="hide ? 'Show!':'Hide!'">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </label>

                                <mat-error>
                                    Password is <strong>required</strong>
                                </mat-error>

                            </mat-form-field>
                            <mat-slide-toggle color="primary">Remember me!</mat-slide-toggle>
                            <button mat-raised-button class="bg-orange" type="submit"
                                (click)="LoginSubmit();" [disabled]="!loginForm.valid">Log In</button>
                                <button mat-stroked-button color="warn" class="fgpass bg-purple">Forget Password?</button>
                        </form>
                    </mat-card>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
</div>
<app-footer></app-footer>
