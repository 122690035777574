<div class="container py-5">

    <div class="row">
        <div class="col-md-12 text-center">
            <h2>View on IndiaMart</h2>
        </div>
    </div>
    <hr class="text-primary">

    <div class="row">
        <div class="col-sm-4 cols text-center">
            <mat-card>
                <img class="indmart" src="assets/images/application/india-mart-verified.png"
                    (click)="OnClickTrust()">
                <h4>Certificate Of Trust</h4>
                <p class="orange indiamart-p">Trust Seal Verified</p>
            </mat-card>
        </div>
        <div class="col-sm-4 cols text-center">
            <mat-card>
                <img class="indmart" src="assets/images/application/indiamart-member.png" (click)="OnClickMember()">
                <h4>IndiaMart Member</h4>
                <p class="orange indiamart-p">Membership With IndiaMart</p>
            </mat-card>
        </div>
        <div class="col-sm-4 cols text-center">
            <mat-card>
                <img class="indmart" src="assets/images/application/pay-with-indiamart.png" (click)="OnClickPay()">
                <h4>Pay With IndiaMart</h4>
                <p class="orange indiamart-p">We Accept Online Payments</p>
            </mat-card>
        </div>
    </div>


</div>