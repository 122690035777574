<div class="container py-5">

    <mat-card>
        <div class="row">
            <div class="col-sm-1 col-md-1"></div>
            <div class="col-sm-4 col-md-4 hover-nav">
                <h4 class="text-center">Machinery Products</h4>
                <ul id="list1" class="list-group mt-4 pb-4">
                    <li (click)="ListItemClick(product.ModelName);" *ngFor='let product of productList'
                        class="list-group-item d-flex justify-content-between align-items-center border-left-0 border-right-0"
                        matTooltip="Click to View Details" matTooltipPosition="left">
                        {{product.Name}}
                        <i class="fa fa-arrow-circle-o-right ml-auto pr-3" aria-hidden="true"></i>
                    </li>
                </ul>
            </div>
            <div class="col-sm-2 col-md-2"></div>
            <div class="col-sm-4 col-md-4 hover-nav">
                <h4 class="text-center">Raw Material Products</h4>
                <ul id="list2" class="list-group mt-4 pb-4">
                    <li (click)="RawListItemClick(product.ModelName);" *ngFor='let product of rawmaterialList'
                        class="list-group-item d-flex justify-content-between align-items-center border-left-0 border-right-0"
                        matTooltip="Click to View Details" matTooltipPosition="left">
                        {{product.Name}}
                        <i class="fa fa-arrow-circle-o-right ml-auto pr-3" aria-hidden="true"></i>
                    </li>
                </ul>
            </div>
            <div class="col-sm-1 col-md-1"></div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="row mbr-justify-content-center">
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-volume-up fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Nature of Business
                                </h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">Manufacturer</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-trophy fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Annual Turnover</h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">Rs. 1 - 2 Crore</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-globe fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Year of Establishment
                                </h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">2017</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-trophy fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">GST Number</h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">27AATFP4260C1Z4</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-trophy fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Legal Status of Firm
                                </h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">Partnership Firm</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mbr-col-md-12">
                        <div class="wrap">
                            <div class="ico-wrap">
                                <span class="mbr-iconfont fa-calendar fa"></span>
                            </div>
                            <div class="text-wrap vcenter">
                                <h3 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Total Number of
                                    Employees
                                </h3>
                                <p class="mbr-fonts-style text1 mbr-text display-6">26 to 50 People</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </mat-card>

</div>
