<app-header></app-header>
<div class="container py-5">
    <div class="card">
        <div class="row">
            <div class="col-md-6 text-center">
                <h4 class="text-center">Drop us a line...</h4>
                <hr>
                <img [src]='firstImgPath' class="img-fluid img-thumbnail"
                    alt="Responsive image">
            </div>
            <div class="col-md-6">

                <div class="header">

                    <h4 class="text-center">Drop us a line...</h4>
                    <p class="text-center">submit below form to get quote.</p>

                    <svg version="1.1" id="Layer" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 113 120"
                        xml:space="preserve" preserveAspectRatio="none">
                        <g>
                            <path class="st0" d="M93.8,87.4c16.7-28.2,23.9-51,11.5-70.9C100.1,8.2,92.4,4.3,84,2.7c6.1,12.9,6.3,28.3-0.4,41.7
                          c5,2.6,8.3,7.9,8.3,13.9c0,8.7-7,15.7-15.7,15.7c-4.3,0-8.2-1.7-11.1-4.6l-19,14.8c0.7,1.4,0.4,3.1-0.9,4.1l-4,3.1
                          c-1.4,1.1-3.5,0.8-4.5-0.6l-0.1-0.1c-1.1-1.4-0.8-3.5,0.6-4.5l4-3.1c1.3-1,3-0.9,4.2,0.2l18.9-14.8c-2.4-2.8-3.8-6.4-3.8-10.3
                          c0-8.7,7-15.7,15.7-15.7c0.5,0,1,0,1.5,0.1c6.9-13.1,6.2-28.5-1-40.7c-10-0.5-20.3,1.2-28.4,2.2C31,6.2,26.8,1,26.8,1
                          c-3.8,11.5,5.9,17,5.9,17s1.7-0.8-2.4,4.2c-4.1,5.1-5.2,15.6-5.8,18.4c-0.6,2.8,1.6,2.7,2.3,5.8c0.7,3.1-9.2,16.4-10.2,19.7
                          c-1,3.3,8.1,6.9,8.1,6.9l-1.4,4.5c0.7,2.5,13.9,2,13.9,2c-2.8,4.2-10.5,3.7-10.5,3.7l-2.3,1.6c0,0,1.3,1.7,2.4,2.7
                          c1.1,1,1.1,6.2,1.1,6.2c-1.3,8.6,1,10.3,5.9,12.5s12.6-3.1,12.6-2.1c0,1-0.1,19.8-0.1,19.8c29.7,14.6,51.4-6.4,51.4-6.4
                          C95,109.8,93.8,87.4,93.8,87.4z" />
                        </g>
                    </svg>

                </div>

                <div class="container" *ngIf="!post; else forminfo" novalidate>
                    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

                        <mat-form-field class="form-element">
                            <input matInput placeholder="Emailaddress" formControlName="email">
                            <i [ngClass]="!formGroup.controls['email'].valid ? 'fa fa-times-circle' : 'fa fa-check-circle'" [hidden]="!formGroup.controls['email'].touched" aria-hidden="true" matSuffix></i>
                            <mat-error
                                *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
                                {{ getErrorEmail() }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-element">
                            <input matInput placeholder="Name" formControlName="name">
                            <i [ngClass]="!formGroup.controls['name'].valid ? 'fa fa-times-circle' : 'fa fa-check-circle'" [hidden]="!formGroup.controls['name'].touched" aria-hidden="true" matSuffix></i>
                            <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                                Required field, must be grater than 3 characters.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="form-element">
                            <textarea matInput placeholder="Description" formControlName="description"
                            #input maxlength="200" hintLabel="Max 200 characters"></textarea>
                            <mat-hint align="end">{{input.value?.length || 0}}/200</mat-hint>
                            <i [ngClass]="!formGroup.controls['description'].valid ? 'fa fa-times-circle' : 'fa fa-check-circle'" [hidden]="!formGroup.controls['description'].touched" aria-hidden="true" matSuffix></i>
                            <mat-error
                                *ngIf="!formGroup.controls['description'].valid && formGroup.controls['description'].touched">
                                Required field, must be between 50 and 200 characters.
                            </mat-error>
                        </mat-form-field>

                        <!-- <mat-form-field class="form-element">
                            <input matInput placeholder="Password" formControlName="password">
                            <mat-hint>Choose a password of at least eight characters, one uppercase letter and one
                                number</mat-hint>
                            <mat-error
                                *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
                                {{ getErrorPassword() }}
                            </mat-error>
                        </mat-form-field> -->

                        <!-- <div class="form-element">
                            <mat-checkbox formControlName="validate" value="1">Name min. 3 characters</mat-checkbox>
                        </div> -->

                        <div class="form-element">
                            <button mat-raised-button color="primary" type="submit" class="button"
                                [disabled]="!formGroup.valid"
                                (click)="GetQuoteSubmit();">Submit</button>
                        </div>

                    </form>
                </div>

                <ng-template #forminfo>
                    <div class="container">
                        <div class="alert alert-success" role="alert">
                            <h4 class="alert-heading text-center">Well done!</h4>
                            <p>Your request has been submitted, we will get in touch with you as soon as possible.</p>
                            <hr>
                            <p class="mb-0">{{ post | json }}</p>
                          </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
