<nav class="navbar navbar-expand-sm navbar-dark fixed-top alter">
  <div class="navbar-brand">
    <a href="#">
      <img src="assets/images/application/pe-logo.png" class="logope" alt="PE">
    </a>&nbsp;&nbsp;&nbsp;
    <a href="https://wa.me/+919561370024?text=Hello there!" id="popupwhatsapp" target="popup"
      onclick="window.open('https://wa.me/+919561370024?text=Hello there!','popup','width=500,height=500'); return false;">
      <i class='fa fa-whatsapp fa-lg'></i>&nbsp;&nbsp;+91 9561370024</a>
  </div>


  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">
    <div class="navbar-nav">
      <a href="#" class="nav-item nav-link" routerLinkActive="active"><i class="fa fa-home fa-fw"
          aria-hidden="true"></i>&nbsp;Home</a>
      <!-- <a [routerLink]="['/rawproductlist']" class="nav-item nav-link"><i class="fa fa-shopping-cart"
          aria-hidden="true"></i>&nbsp;Corrugated</a> -->
      <a [routerLink]="['/productlist']" class="nav-item nav-link"><i class="fa fa-link"
          aria-hidden="true"></i>&nbsp;Machinery</a>
      <a [routerLink]="['/rawproductlist']" class="nav-item nav-link"><i class="fa fa-shopping-cart"
          aria-hidden="true"></i>&nbsp;Raw Material</a>
      <a [routerLink]="['/contactus']" class="nav-item nav-link" routerLinkActive="active"><i
          class="fa fa-volume-control-phone" aria-hidden="true"></i>&nbsp;Contact Us</a>
    </div>
    <div class="navbar-nav ml-auto">
      <a [routerLink]="['/login']" class="nav-item nav-link"><i class="fa fa-sign-in"
          aria-hidden="true"></i>&nbsp;Login</a>
    </div>
  </div>
</nav><br><br>
