
    <div class="container py-5">

        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class='purple'>Welcome!</h1>
                        <h2>Know More About Us</h2>
                    </div>
                </div>
                <hr class="text-primary">
                <p class="about-p">Established in the year 2017 at Pune, Maharashtra, we <b>“Prathamesh Enterprises”</b>
                    is a
                    Proprietor based firm, engaged as the foremost Manufacturer of Paper Plates, Paper Cup, etc. Our
                    products are high in demand due to their premium quality and affordable prices. Furthermore, we
                    ensure to timely deliver these products to our clients, through this we have gained a huge clients
                    base in the market.<span [ngClass]="{'show-less': showDots}">..</span></p>
                <span [ngClass]="{'show-less': showShortDesciption}">
                    <p class="about-p">Owing to our quality norms and high-quality paper cup making machine, we are
                        famous in the
                        domain. Furthermore, we check the skills of professionals during hiring and provide them with
                        respective tasks as per their specialization in this domain. Moreover, we regularly organize
                        training sessions to keep pace with changing market demands. Apart from this, we are transparent
                        in business dealings that have helped us gain the confidence of the customers.
                        Our mentor<b> Mr. Ishwar Sawant </b>has guided us to be able to reach such heights. His
                        knowledge helps us to cater to the needs of patrons.</p>
                </span>
                <button type="button" class="btn" (click)="alterDescriptionText()">
                    {{ ShortDesciption }}
                </button>
            </div>
            <div class="col-md-2"></div>
        </div>

    </div>
