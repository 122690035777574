import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiURI } from 'src/app/secure/ApiURI';
import { UserService } from 'src/app/userservice/user.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  formGroup!: FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';

  constructor(private formBuilder: FormBuilder,private dataService: UserService,
    public apiURI: ApiURI) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formGroup = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.pattern(emailregex)], this.checkInUseEmail],
      'name': [null, [Validators.required, Validators.minLength(3)]],
      'phone': [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      'description': [null, [Validators.required, Validators.minLength(50), Validators.maxLength(200)]]
    });
  }

  checkInUseEmail(control: any) {
    debugger;
    // mimic http database access
    let db = ['tony@gmail.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 1000)
    })
  }



  getErrorEmail() {
    return this.formGroup.controls['email'].hasError('required') ? 'This field is required' :
      this.formGroup.controls['email'].hasError('pattern') ? 'Not a valid emailaddress' :
        this.formGroup.controls['email'].hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
  }

  getErrorPhone() {
    return this.formGroup.controls['phone'].hasError('required') ? 'This field is required' :
      this.formGroup.controls['phone'].hasError('pattern') ? 'Not a valid mobile number' : '';
  }

  onSubmit(post: any) {
    this.post = post;
  }

  ContactSubmit() {
    debugger;
    const params = {
      PageName:"contact us",
      Mobile:this.formGroup.value.phone,
      Name: this.formGroup.value.name,
      EmailAddress: this.formGroup.value.email,
      Description: this.formGroup.value.description
    };
    if (this.formGroup.valid) {
      this.dataService.PostData(this.apiURI.SendMail, params).subscribe(data => {
        this.post = data;
      })
    }
    else{
     //
    }
  }

}
