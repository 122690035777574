import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  getProductByModelName(item: any) {
    return ProductDetails.find(arritem => arritem.ModelName === item);
  }

  getRawProductByModelName(item: any) {
    return RawMaterialDetails.find(arritem => arritem.ModelName === item);
  }

  getImagesByModelName(item: any) {
    return ImageDetails.filter(arritem => arritem.ModelName === item);
  }

}

export interface Images {
  ImagId: number;
  ModelName: string;
  Image: string;
}

export interface Product {

  //Basic Info
  ID: number;
  Name: string;
  ModelName: string;
  Image: string;
  Description: string;
  VotingMsg: string;

  //Machine Details
  NumberOfDies: string;
  MachineType: string;
  Brand: string;
  Capacity: string;
  Price: string;

  //Technical Specifications
  DieSize: string;
  Production: string;
  RawMaterial: string;
  Weight: string;
  PowerConsumption: string;
  ElectricMotor: string;

}

export interface RawMaterial {
  ID: number;
  ModelName:string;
  Name:string;
  Price:string;
  Description:string;
  Image:string;
  Type:string;
  Usage: string;
  Color: string;
  PackagingType: string;
  RollWeight: string;
  GSM: string;
  PECoated: string;
  Size: string;
  Pattern: string;
  Width: string;
  Shape: string;
}

export const ImageDetails: Images[] = [
  {
    ImagId: 1,
    ModelName: 'PE-M01',
    Image: 'assets/images/machines/PaperDishMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M01',
    Image: 'assets/images/machines/PaperDishMachine2.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M02',
    Image: 'assets/images/machines/DonaMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M02',
    Image: 'assets/images/machines/DonaMakingMachine2.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M03',
    Image: 'assets/images/machines/AutomaticThaliMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M03',
    Image: 'assets/images/machines/AutomaticThaliMakingMachine2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'PE-M03',
    Image: 'assets/images/machines/AutomaticThaliMakingMachine3.jpg'
  },
  {
    ImagId: 4,
    ModelName: 'PE-M03',
    Image: 'assets/images/machines/AutomaticThaliMakingMachine4.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M04',
    Image: 'assets/images/machines/PlateMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M04',
    Image: 'assets/images/machines/PlateMakingMachine2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'PE-M04',
    Image: 'assets/images/machines/PlateMakingMachine3.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M05',
    Image: 'assets/images/machines/FullyAutomaticDonaMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M05',
    Image: 'assets/images/machines/FullyAutomaticDonaMakingMachine2.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M06',
    Image: 'assets/images/machines/PaperPlateMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M06',
    Image: 'assets/images/machines/PaperPlateMakingMachine2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'PE-M06',
    Image: 'assets/images/machines/PaperPlateMakingMachine3.jpg'
  },
  {
    ImagId: 4,
    ModelName: 'PE-M06',
    Image: 'assets/images/machines/PaperPlateMakingMachine4.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'PE-M07',
    Image: 'assets/images/machines/DisposablePlateMakingMachine1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'PE-M07',
    Image: 'assets/images/machines/DisposablePlateMakingMachine2.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'SPPRM',
    Image: 'assets/images/rawmaterial/SPPRM1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'SPPRM',
    Image: 'assets/images/rawmaterial/SPPRM2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'SPPRM',
    Image: 'assets/images/rawmaterial/SPPRM3.jpg'
  },
  {
    ImagId: 4,
    ModelName: 'SPPRM',
    Image: 'assets/images/rawmaterial/SPPRM4.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'BLPPRM',
    Image: 'assets/images/rawmaterial/BLPPRM1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'BLPPRM',
    Image: 'assets/images/rawmaterial/BLPPRM2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'BLPPRM',
    Image: 'assets/images/rawmaterial/BLPPRM3.jpg'
  },
  {
    ImagId: 4,
    ModelName: 'BLPPRM',
    Image: 'assets/images/rawmaterial/BLPPRM4.jpg'
  },
  {
    ImagId: 1,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB1.jpg'
  },
  {
    ImagId: 2,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB2.jpg'
  },
  {
    ImagId: 3,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB3.jpg'
  },
  {
    ImagId: 4,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB4.jpg'
  },
  {
    ImagId: 5,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB5.jpg'
  },
  {
    ImagId: 6,
    ModelName: 'CPB',
    Image: 'assets/images/rawmaterial/CB6.jpeg'
  }
]
export const RawMaterialDetails: RawMaterial[] = [
  {
    ID: 1,
    ModelName:'SPPRM',
    Name:'Silver Paper Plate Raw Material',
    Price:'Rs 44 / Kg',
    Description:'Providing you the best range of Silver Paper Plate Raw Material, Silver Paper Plates Raw Material and Banana Leaf Paper Plate Raw Material with effective & timely delivery.',
    Image: 'assets/images/rawmaterial/SPPRM1.jpg',
    Usage: 'Paper Plate Making',
    Type:'ONE SIDE SILVER COTTED',
    Color: 'Silver',
    PackagingType: 'ROLL',
    RollWeight: '',
    GSM: 'Less than 150',
    PECoated: '',
    Size: 'all sizes availble',
    Pattern: 'Plain',
    Width: '',
    Shape: ''
  },
  {
    ID: 2,
    ModelName:'BLPPRM',
    Name:'Banana Leaf Paper Plate Raw Material',
    Price:'Rs 1 / No',
    Description:'Providing you the best range of Silver Paper Plate Raw Material, Silver Paper Plates Raw Material and Banana Leaf Paper Plate Raw Material with effective & timely delivery.',
    Image: 'assets/images/rawmaterial/BLPPRM1.jpg',
    Usage: 'Paper Plate Making',
    Type:'TWO SIDE GREEN COTTED',
    Color: 'Green',
    PackagingType: 'Square Cutting',
    RollWeight: '',
    GSM: 'Less than 150',
    PECoated: '',
    Size: 'all sizes availble',
    Pattern: 'Plain/Design',
    Width: '',
    Shape: ''
  },
  {
    ID: 3,
    ModelName:'CPB',
    Name:'Corrugated Packaging Boxes And Packaging Boxes',
    Price:'152 / Piece',
    Description:'Providing you the best range of Regular Corrugated Box with effective & timely delivery.',
    Image: 'assets/images/rawmaterial/CB1.jpg',
    Usage: 'Packaging Boxes',
    Type:'Double Wall 5 Ply',
    Color: 'Brown',
    PackagingType: 'As pe your requirment',
    RollWeight: '',
    GSM: '',
    PECoated: '',
    Size: 'all sizes availble',
    Pattern: 'Plain/Design',
    Width: '',
    Shape: ''
  }
]
export const ProductDetails: Product[] = [
  {
    //Basic Info
    ID: 1,
    Name: 'Paper Dish Machine',
    ModelName: 'PE-M01',
    Image: 'assets/images/machines/PaperDishMachine1.jpg',
    Description: 'Within the short time period, we are engaged in presenting an excellent grade ofPaper Dish Machine.The user friendly control panel makes it a perfect Paper Plate making machines in the industry. These machines automatic feed raw material for manufacturing the Plates. Flawless working life and excellent performance makes the machines produce with provided paper material. Besides, the machines can be bought from us at cost effective prices.',
    VotingMsg: '96% of buyers enjoyed this product! (88 votes)',
    //Machine Details
    NumberOfDies: '2',
    MachineType: 'Semi-Automatic',
    Brand: 'Made in india',
    Capacity: '2000 - 5000 pc/hr',
    Price: 'Approx. ₹ 55,000 / Unit',
    //Technical Specifications
    DieSize: '4"-14"',
    Production: '2000 - 5000 pc/hr',
    RawMaterial: 'Paperboard',
    Weight: '300KG',
    PowerConsumption: '2-2.5 Unit/Hr',
    ElectricMotor: '2HP'
  },
  {
    //Basic Info
    ID: 2,
    Name: 'Dona Making Machine',
    ModelName: 'PE-M02',
    Image: 'assets/images/machines/DonaMakingMachine1.jpg',
    Description: 'Manufacturer of a wide range of products which include hydraulic triple die dona making machine, fully automatic dona machine, hand operated dona making machine, manual hand press dona making machine, fully automatic single die dona making machine and hydraulic dona making machine.',
    VotingMsg: '98% of buyers enjoyed this product! (188 votes)',
    //Machine Details
    NumberOfDies: '2',
    MachineType: 'Automatic',
    Brand: 'Made in india',
    Capacity: '>25000 Dona Per Day',
    Price: 'Approx. ₹ 82,000 / Unit',
    //Technical Specifications
    DieSize: '4"-8"',
    Production: '2700-3000 Pcs/Hr',
    RawMaterial: '80 GSM to 180 GSM',
    Weight: '250KG',
    PowerConsumption: '2 Unit/Hr',
    ElectricMotor: '1HP'
  },
  {
    //Basic Info
    ID: 3,
    Name: 'Automatic Thali Making Machine',
    ModelName: 'PE-M03',
    Image: 'assets/images/machines/AutomaticThaliMakingMachine1.jpg',
    Description: 'Leading Manufacturer of fully automatic hydraulic thali, fully automatic thali dona machine, automatic thali making machine, automatic thali making machines, fully automatic paper thali making machine and fully automatic paper thali making machines from Chakan.',
    VotingMsg: '90% of buyers enjoyed this product! (78 votes)',
    //Machine Details
    NumberOfDies: '2',
    MachineType: 'Automatic',
    Brand: 'Made in india',
    Capacity: '20-40 pieces per min',
    Price: 'Approx. ₹ 1.25 Lakhs / Unit',
    //Technical Specifications
    DieSize: '4"-14"',
    Production: '2100 Pis/Hr.',
    RawMaterial: 'Paper',
    Weight: '350KG',
    PowerConsumption: '1.5 Unit / Hr.',
    ElectricMotor: '1HP'
  },
  {
    //Basic Info
    ID: 4,
    Name: 'Plate Making Machine',
    ModelName: 'PE-M04',
    Image: 'assets/images/machines/PlateMakingMachine1.jpg',
    Description: 'Backed by our years of industry experience, we are betrothed in presenting an excellent quality of Paper Making Machine.This machine is used for making all kinds of paper Plates. The offered machine use Paper raw material, shapes and design the plate, and produces 4000 plates per hour. Entire production line assembled into one body structure.',
    VotingMsg: '80% of buyers enjoyed this product! (100 votes)',
    //Machine Details
    NumberOfDies: '1',
    MachineType: 'Semi automatic hydraulic machine',
    Brand: 'Made in india',
    Capacity: '40-60 pieces/ min',
    Price: 'Approx. ₹ 58,000 / Unit',
    //Technical Specifications
    DieSize: '4”-15”',
    Production: '2000-3000 Pcs/Hr',
    RawMaterial: '80 GSM to 450 GSM',
    Weight: '450KG',
    PowerConsumption: '1.5 Unit / Hr.',
    ElectricMotor: '2HP'
  },
  {
    //Basic Info
    ID: 5,
    Name: 'Fully Automatic Dona Making Machine',
    ModelName: 'PE-M05',
    Image: 'assets/images/machines/FullyAutomaticDonaMakingMachine1.jpg',
    Description: 'Our product range includes a wide range of fully automatic multipurpose dona thali making machine, fully automatic high speed dona making machine, fully automatic double die dona making machine, fully automatic single die dona making machine, fully automatic dona making machine and fully automatic paper dona making machine.',
    VotingMsg: '97% of buyers enjoyed this product! (140 votes)',
    //Machine Details
    NumberOfDies: '2',
    MachineType: 'Fully Automatic',
    Brand: 'Made in india',
    Capacity: '40-60 pieces/ min',
    Price: 'Approx. ₹ 42,000 / Unit',
    //Technical Specifications
    DieSize: '4”-8”',
    Production: '2700-3000 Pcs/Hr',
    RawMaterial: '80 GSM to 180 GSM',
    Weight: '250KG',
    PowerConsumption: '1 Unit / Hr.',
    ElectricMotor: '1HP'
  },
  {
    //Basic Info
    ID: 6,
    Name: 'Paper Plate Making Machine',
    ModelName: 'PE-M06',
    Image: 'assets/images/machines/PaperPlateMakingMachine1.jpg',
    Description: 'Utilizing by the immeasurable experience of this space, we are occupied with assembling the prevalent nature of Paper Plate Making Machine. Its rich properties, for example, superior, low upkeep and more serving life, make the machine profoundly requested in paper plate making applications. For assembling the offered paper plate making machine, we make utilization of most recent hardware.',
    VotingMsg: '77% of buyers enjoyed this product! (40 votes)',
    //Machine Details
    NumberOfDies: '1',
    MachineType: 'Fully Automatic',
    Brand: 'Made in india',
    Capacity: '2000 - 5000 pc/hr',
    Price: 'Approx. ₹ 55,000 / Unit',
    //Technical Specifications
    DieSize: '4"-14"',
    Production: '1900-2900 Pcs/Hr',
    RawMaterial: 'Paper',
    Weight: '400KG',
    PowerConsumption: '1 Unit / Hr.',
    ElectricMotor: '1HP'
  },
  {
    //Basic Info
    ID: 7,
    Name: 'Disposable Plate Making Machine',
    ModelName: 'PE-M07',
    Image: 'assets/images/machines/DisposablePlateMakingMachine1.jpg',
    Description: 'Utilizing by the immeasurable experience of this space, we are occupied with assembling the prevalent nature of Paper Plate Making Machine. Its rich properties, for example, superior, low upkeep and more serving life, make the machine profoundly requested in paper plate making applications. For assembling the offered paper plate making machine, we make utilization of most recent hardware.',
    VotingMsg: '89% of buyers enjoyed this product! (140 votes)',
    //Machine Details
    NumberOfDies: '1',
    MachineType: 'Fully Automatic',
    Brand: 'Made in india',
    Capacity: '2000 - 5000 pc/hr',
    Price: 'Approx. ₹ 55,000 / Unit',
    //Technical Specifications
    DieSize: '4"-14"',
    Production: '2000-3000 Pcs/Hr',
    RawMaterial: '80 GSM to 450 GSM',
    Weight: '450KG',
    PowerConsumption: '1 Unit / Hr.',
    ElectricMotor: '2HP'
  }
]
