import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './materialmodule/material.module';

import { HeaderComponent } from './public/header/header.component';
import { FooterComponent } from './public/footer/footer.component';
import { SliderComponent } from './public/slider/slider.component';
import { HomeComponent } from './public/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductlistComponent } from './public/productlist/productlist.component';
import { ProductdetailsComponent } from './public/productdetails/productdetails.component';
import { AboutusComponent } from './public/aboutus/aboutus.component';
import { ProductandservicesComponent } from './public/productandservices/productandservices.component';
import { OurvideosComponent } from './public/ourvideos/ourvideos.component';
import { ViewonindiamartComponent } from './public/viewonindiamart/viewonindiamart.component';
import { UserloginComponent } from './public/userlogin/userlogin.component';
import { ContactusComponent } from './public/contactus/contactus.component';
import { SendmessageComponent } from './public/sendmessage/sendmessage.component';
import { GetquoteComponent } from './public/getquote/getquote.component';
import { ProductscrollComponent } from './public/productscroll/productscroll.component';
import { RawproductlistComponent } from './public/rawproductlist/rawproductlist.component';
import { RawproductdetailsComponent } from './public/rawproductdetails/rawproductdetails.component';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { NavbarComponent } from './admin/shared/navbar/navbar.component';
import { SidebarComponent } from './admin/shared/sidebar/sidebar.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    HomeComponent,
    ProductlistComponent,
    ProductdetailsComponent,
    AboutusComponent,
    ProductandservicesComponent,
    OurvideosComponent,
    ViewonindiamartComponent,
    UserloginComponent,
    ContactusComponent,
    SendmessageComponent,
    GetquoteComponent,
    ProductscrollComponent,
    RawproductlistComponent,
    RawproductdetailsComponent,
    AdminLayoutComponent,
    DashboardComponent,
    SidebarComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
