import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../productData/product-details-data';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.scss']
})
export class ProductdetailsComponent implements OnInit {

  product!: any;
  ImagesList!: any;
  firstImgPath: any;

  constructor(private productServ: ProductService, private router: Router) {
    this.ImagesList = productServ.getImagesByModelName(localStorage.getItem('ModelName'));
    this.product = productServ.getProductByModelName(localStorage.getItem('ModelName'));
    this.firstImgPath = this.ImagesList[0].Image;
    this.ImagesList =this.ImagesList.slice(1);
  }

  ngOnInit(): void {
  }

  getBestQuote(modelname:any){
    localStorage.removeItem('quoteimage');
    localStorage.removeItem('quoteproduct');

    localStorage.setItem('quoteimage',this.firstImgPath);
    localStorage.setItem('quoteproduct',this.product.Name);

    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.router.navigateByUrl('/getquote');
  }
}
