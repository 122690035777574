import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  showShortDesciption = true;
  showDots = false;
  ShortDesciption = "Let's Know More...";

  constructor() {
    
   }

  ngOnInit(): void {
  }
  
  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
    this.showDots=!this.showDots;
    if (this.showShortDesciption)
      this.ShortDesciption = "Let's Know More...";
    else
      this.ShortDesciption = "Show Less";
  }

}
