<div class="container py-5">

    <div class="row">
        <div class="col-md-12 text-center">
            <h2>Our Videos</h2>
        </div>
    </div>
    <hr class="text-primary">

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
            <div class="embed-responsive embed-responsive-16by9">
                <iframe data-img="https://img.youtube.com/vi/vGCC-nFr2Xk/hqdefault.jpg" id="homevideo"
                    alt="Video Section Image" data-dataimg="https://img.youtube.com/vi/vGCC-nFr2Xk/hqdefault.jpg"
                    src="https://www.youtube.com/embed/vGCC-nFr2Xk?enablejsapi=1&amp;origin=https%3A%2F%2Fwww.indiamart.com&amp;widgetid=1"
                    frameborder="0" allowfullscreen="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="YouTube video player" 
                    data-gtm-yt-inspected-59460_75="true"></iframe>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>

</div>