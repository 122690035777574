<app-header></app-header>
<div class="maindiv pe-bg">
    <div class="container py-5">
        <div class="row text-center text-white mb-9">
            <div class="col-lg-7 mx-auto">
                <h1 class="display-9">{{product.Name}}</h1>
            </div>
        </div>
        <div class="card">
            <div class="container-fliud">
                <div class="wrapper row">
                    <div class="preview col-md-6">
                        <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-interval="10000">
                                    <img [src]='firstImgPath' class="img-fluid img-thumbnail" alt="">
                                </div>
                                <div class="carousel-item" data-interval="2000" *ngFor="let imgsrc of ImagesList">
                                    <img [src]='imgsrc.Image' class="img-fluid img-thumbnail" alt="">
                                </div>

                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleInterval" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleInterval" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                    </div>
                    <div class="details col-md-6">
                        <strong class="orange titletbl">Product Details:</strong>
                        <div class="table-details">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td class="tblleftcol">Usage/Application</td>
                                        <td class="tblrightcol">{{product.Usage}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Type</td>
                                        <td class="tblrightcol">{{product.Type}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Color</td>
                                        <td class="tblrightcol">{{product.Color}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Pattern</td>
                                        <td class="tblrightcol">{{product.Pattern}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Packaging Type</td>
                                        <td class="tblrightcol">{{product.PackagingType}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">GSM</td>
                                        <td class="tblrightcol">{{product.GSM}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Size</td>
                                        <td class="tblrightcol">{{product.Size}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Weight</td>
                                        <td class="tblrightcol">{{product.RollWeight}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">PECoated</td>
                                        <td class="tblrightcol">{{product.PECoated}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Shape</td>
                                        <td class="tblrightcol">{{product.Shape}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="wrapper row" style="padding-top: 10px;">
                    <div class="col-md-6">
                        <strong class="orange titletbl">Description:</strong>
                        <p class="product-description">{{product.Description}}</p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="price purple">current price : <span
                            class="orange">{{product.Price}}</span></h6>
                        
                        <div class="action">
                            <button class="btn" type="button" (click)="getBestQuote('product.ModelName');">Get Best Quote</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>