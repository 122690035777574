<app-header></app-header>
<div class="maindiv pe-bg">
    <div class="container py-5">
        <div class="row text-center text-white mb-9">
            <div class="col-lg-7 mx-auto">
                <h1 class="display-9">{{product.Name}}</h1>
            </div>
        </div>
        <div class="card">
            <div class="container-fliud">
                <div class="wrapper row">
                    <div class="preview col-md-6">

                        <!-- <div class="preview-pic tab-content">
                            <div class="tab-pane active" id="pic-1"><img src="http://placekitten.com/400/252" /></div>
                            <div class="tab-pane" id="pic-2"><img src="http://placekitten.com/400/252" /></div>
                            <div class="tab-pane" id="pic-3"><img src="http://placekitten.com/400/252" /></div>
                            <div class="tab-pane" id="pic-4"><img src="http://placekitten.com/400/252" /></div>
                            <div class="tab-pane" id="pic-5"><img src="http://placekitten.com/400/252" /></div>
                        </div>
                        <ul class="preview-thumbnail nav nav-tabs">
                            <li class="active"><a data-target="#pic-1" data-toggle="tab"><img
                                        src="http://placekitten.com/200/126" /></a></li>
                            <li><a data-target="#pic-2" data-toggle="tab"><img
                                        src="http://placekitten.com/200/126" /></a></li>
                            <li><a data-target="#pic-3" data-toggle="tab"><img
                                        src="http://placekitten.com/200/126" /></a></li>
                            <li><a data-target="#pic-4" data-toggle="tab"><img
                                        src="http://placekitten.com/200/126" /></a></li>
                            <li><a data-target="#pic-5" data-toggle="tab"><img
                                        src="http://placekitten.com/200/126" /></a></li>
                        </ul> -->

                        <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-interval="10000">
                                    <img [src]='firstImgPath' class="img-fluid img-thumbnail" alt="">
                                </div>
                                <div class="carousel-item" data-interval="2000" *ngFor="let imgsrc of ImagesList">
                                    <img [src]='imgsrc.Image' class="img-fluid img-thumbnail" alt="">
                                </div>

                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleInterval" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleInterval" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                    </div>
                    <div class="details col-md-6">
                        <strong class="orange titletbl">Product Details:</strong>
                        <div class="table-details">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td class="tblleftcol">Model Name</td>
                                        <td class="tblrightcol">{{product.ModelName}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Number Of Dies</td>
                                        <td class="tblrightcol">{{product.NumberOfDies}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Machine Type</td>
                                        <td class="tblrightcol">{{product.MachineType}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Capacity</td>
                                        <td class="tblrightcol">{{product.Capacity}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <strong class="orange titletbl">Technical Specifications:</strong>

                        <div class="table-Specifications">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td class="tblleftcol">Die Size</td>
                                        <td class="tblrightcol">{{product.DieSize}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Production</td>
                                        <td class="tblrightcol">{{product.Production}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Raw Material</td>
                                        <td class="tblrightcol">{{product.RawMaterial}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Weight</td>
                                        <td class="tblrightcol">{{product.Weight}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Power Consumption</td>
                                        <td class="tblrightcol">{{product.PowerConsumption}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tblleftcol">Electric Motor</td>
                                        <td class="tblrightcol">{{product.ElectricMotor}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="wrapper row" style="padding-top: 10px;">
                    <div class="col-md-6">
                        <strong class="orange titletbl">Description:</strong>
                        <p class="product-description">{{product.Description}}</p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="price purple">current price : <span
                            class="orange">{{product.Price}}</span></h6>
                        <p class="vote">{{product.VotingMsg}}</p>
                        <div class="action">
                            <button class="btn" type="button" (click)="getBestQuote('product.ModelName');">Get Best Quote</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>