import { Component, OnInit } from '@angular/core';
import { ProductService, ImageDetails } from 'src/app/public/productData/product-details-data';

@Component({
  selector: 'app-productscroll',
  templateUrl: './productscroll.component.html',
  styleUrls: ['./productscroll.component.scss']
})
export class ProductscrollComponent implements OnInit {
  ImagesList: any;


  constructor(private productServ: ProductService) {
    debugger;
    this.ImagesList = ImageDetails;
  }

  ngOnInit(): void {
  }

}
