import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class ApiURI {

    //Email Send
    public readonly SendMail: string = environment.baseUrl + '/User/SendMail';

    // Userdetails
    public readonly Login: string = environment.baseUrl + '/User/Login';
    public readonly GetUserDetailsById: string = environment.baseUrl + '/Admin/GetUserDetailsById';
    public readonly GetAllUserDetails: string = environment.baseUrl + '/Admin/GetAllUserDetails';
    //UserMessages
    public readonly AddUserMessage: string = environment.baseUrl + '/Admin/AddUserMessage';
    public readonly GetAllMessages: string = environment.baseUrl + '/Admin/GetAllMessages';
}
