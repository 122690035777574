<div class="container py-5">
    <div class="row">
        <div class="col-md-12 text-center">
            <h2>Our Products & Services</h2>
        </div>
    </div>
    <hr class="text-primary">

    <div class="MagicScroll" data-options="autoplay: 1000; step: 1; mode: carousel; height: 400;">
        <img [src]='imgsrc.Image' alt="" *ngFor="let imgsrc of ImagesList" class="imgscroll img-thumbnail">
    </div>

</div>