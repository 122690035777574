import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductDetails, RawMaterial, RawMaterialDetails } from 'src/app/public/productData/product-details-data';

@Component({
  selector: 'app-productandservices',
  templateUrl: './productandservices.component.html',
  styleUrls: ['./productandservices.component.scss']
})
export class ProductandservicesComponent implements OnInit {

  productList!: Product[];
  rawmaterialList!: RawMaterial[];

  constructor(private router: Router) {
    this.productList = ProductDetails;
    this.rawmaterialList = RawMaterialDetails;
   }

  ngOnInit(): void {
  }

  ListItemClick(modelname:any){
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.router.navigateByUrl('/productdetails');
  }
  RawListItemClick(modelname:any){
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.router.navigateByUrl('/rawproductdetails');
  }

}
