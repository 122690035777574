import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageDetails, Images, ProductService, RawMaterialDetails, RawMaterial } from '../productData/product-details-data';

@Component({
  selector: 'app-rawproductlist',
  templateUrl: './rawproductlist.component.html',
  styleUrls: ['./rawproductlist.component.scss']
})
export class RawproductlistComponent implements OnInit {

  imagepath = null;
  imgtitle = null;
  showModal!: boolean;
  rawmaterialList!: RawMaterial[];
  ImagesList!: Images[];

  constructor(private productServ: ProductService, private router: Router) {
    localStorage.removeItem('ModelName');
    this.rawmaterialList = RawMaterialDetails;
    this.ImagesList = ImageDetails;
  }

  ngOnInit(): void {
  }

  SetCurrentModelName(modelname: any) {
    debugger;
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.router.navigateByUrl('/rawproductdetails');
  }

  show(img: any, title: any, modelname: any) {
    localStorage.removeItem('ModelName');
    localStorage.setItem('ModelName', modelname);
    this.imagepath = img;
    this.imgtitle = title;
    this.showModal = true;
    this.ImagesList = this.productServ.getImagesByModelName(localStorage.getItem('ModelName'));
    this.ImagesList = this.ImagesList.slice(1);
  }
  hide() {
    this.showModal = false;
  }
}
