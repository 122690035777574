<app-header></app-header>
<app-slider></app-slider>

<div class="pe-bg">

<app-aboutus></app-aboutus>
<app-productscroll></app-productscroll>
<app-productandservices></app-productandservices>
<app-ourvideos></app-ourvideos>
<app-viewonindiamart></app-viewonindiamart>

</div>

<app-footer></app-footer>