import { RawproductdetailsComponent } from './public/rawproductdetails/rawproductdetails.component';
import { RawproductlistComponent } from './public/rawproductlist/rawproductlist.component';
import { GetquoteComponent } from './public/getquote/getquote.component';
import { UserloginComponent } from './public/userlogin/userlogin.component';
import { ProductdetailsComponent } from './public/productdetails/productdetails.component';
import { ProductlistComponent } from './public/productlist/productlist.component';
import { HomeComponent } from './public/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactusComponent } from './public/contactus/contactus.component';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';

const routes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, //  redirect to `first-component`
  { path: 'home', component: HomeComponent },
  { path: 'productlist', component: ProductlistComponent },
  { path: 'productdetails', component: ProductdetailsComponent },
  { path: 'login', component: UserloginComponent },
  { path: 'getquote', component: GetquoteComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'rawproductlist', component: RawproductlistComponent },
  { path: 'rawproductdetails', component: RawproductdetailsComponent },
  {
    path: 'dashboard',
    component: AdminLayoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
