import { UserService } from '../../userservice/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiURI } from 'src/app/secure/ApiURI';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  Messages!: any;
  accessToken!: any;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    public apiURI: ApiURI,
    private router: Router) {

  }


  ngOnInit(): void {
    //this.GetAllMessages();
  }

  GetAllMessages() {
    debugger;
    this.userService.GetDataSecure(this.apiURI.GetAllMessages).subscribe(data => {
      this.Messages = data;
    })
  }

}

