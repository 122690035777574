<div class="wrapper">
  <div class="sidebar" data-color="danger" data-background-color="white">
      <app-sidebar></app-sidebar>
      <div class="sidebar-background" ></div>
  </div>
  <div class="main-panel">
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>

  </div>

</div>
